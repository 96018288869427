@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
.noto {
  font-family: "Noto Sans", sans-serif;
}
.francois {
  font-family: "Francois One", sans-serif;
}
.bg-theme-default {
  @apply bg-gradient-to-b bg-dark-2024 text-white;
}
.bg-theme-primary {
  @apply bg-gradient-to-r from-orange to-red text-light;
}

.transition-default {
  @apply transition-all duration-500 ease-in-out cursor-pointer;
}

.my-container {
  @apply p-4;
}

.between {
  @apply flex justify-between items-center;
}
.end {
  @apply flex justify-end items-center;
}
.around {
  @apply flex justify-around items-center;
}
.start {
  @apply flex justify-start items-center;
}
.center {
  @apply flex justify-center items-center;
}

.col-mobile {
  @apply flex-col lg:flex-row;
}

header {
  @apply text-2xl font-bold my-container noto;
}

nav .links {
  @apply text-base center;
}

.btn {
  @apply text-base text-center p-2 cursor-pointer hover:scale-105 transition-default truncate;
}

.btn-outline {
  @apply border;
}

.btn-light {
  @apply border-light border-0;
}

.btn-rounded {
  @apply rounded-full;
}

.social-links {
  @apply flex flex-wrap gap-4 lg:justify-start justify-center items-center my-2;
}
.social-links > a {
  @apply hover:scale-125 transition-default hover:rotate-3;
}
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}
.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}
.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}
.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}
.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}
.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}
/* Demo Purpose Only*/
.demo {
  font-family: "Raleway", sans-serif;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}
@keyframes animate-company-1 {
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}
.animate-company-1 {
  animation: animate-company-1 5s backwards infinite;
}
@keyframes animate-company-0 {
  0% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(20deg);
  }
}
.animate-company-0 {
  animation: animate-company-0 5s backwards infinite;
}

footer a {
  @apply hover:text-new-blue;
}

::selection {
  color: #fff;
  background: #5520ff;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}
.swal-button:focus {
  box-shadow: none;
}
.swal2-close:focus {
  box-shadow: none !important;
}
